section {
  justify-content: space-around;
  padding: 5px;
  display: flex;
}

section a {
  font-style: normal;
}

.Header {
  background-color: #343434;
  border-bottom: 1rem solid #b76e22;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: flex;
}

h1 {
  text-shadow: 2.01369px -1.35825px #f2eddb;
  letter-spacing: .042em;
  text-transform: uppercase;
  caret-color: #000;
  -webkit-text-stroke: 1.66428px #000;
  -webkit-text-fill-color: transparent;
  font-family: Alfa Slab One;
  font-size: 3rem;
  line-height: 1;
}

* {
  font-family: Lucida Sans, Lucida Sans Regular, Lucida Grande, Lucida Sans Unicode, Geneva, Verdana, sans-serif;
}

.home {
  background: linear-gradient(to right, #c8dfd2 0%, #dce6d6 100%);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100%;
  padding-bottom: 1rem;
  display: flex;
}

.home h2 {
  justify-content: center;
  align-items: center;
}

.columnList {
  flex-direction: column;
  width: 80vw;
  max-width: 45rem;
  margin: 0 auto;
  display: flex;
}

.project {
  background: #e6e6fa4d;
  border: 1px solid #000;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: .5rem;
}

.projectVisualContainer {
  flex-wrap: wrap;
  justify-content: space-around;
  display: flex;
}

.projectImage {
  border-radius: 5px;
  width: auto;
  max-width: 70vw;
  height: auto;
  max-height: 60vh;
  margin: .5rem auto auto;
}

.projectDescription {
  flex-direction: column;
  width: 80vw;
  max-width: 30rem;
  margin: 2rem 0;
  display: flex;
}

.sectionHeader {
  justify-content: space-between;
  display: flex;
}

.sectionHeader h3 {
  margin: auto 0;
}

.sectionHeader h4 {
  justify-content: center;
  align-items: center;
}

.icon {
  height: 2.5rem;
  padding-left: 1rem;
}

.greeting {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  max-width: 30rem;
  margin: 2rem 0;
  display: flex;
}

.greeting p, .greeting h3 {
  text-align: center;
  padding-bottom: 1rem;
}

.stackList {
  grid-template-rows: .4fr .4fr;
  grid-template-columns: .4fr .4fr;
  display: grid;
}

.stack {
  padding: .5rem;
  list-style-type: square;
}

.inConstruction {
  justify-content: center;
  align-items: center;
  font-family: cursive;
  display: flex;
}

.code {
  width: 77vw;
  max-width: 43.5rem;
  height: 10rem;
  margin: 0 auto;
}

* {
  margin: 0;
  padding: 0;
}

main {
  justify-content: center;
  display: flex;
}
/*# sourceMappingURL=index.4016d7e8.css.map */
