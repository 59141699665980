* {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
    'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.home {
  width: 100vw;
  height: 100%;
  background: linear-gradient(to right, #c8dfd2 0%, #dce6d6 100%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding-bottom: 1rem;
}
.home h2 {
  align-items: center;
  justify-content: center;
}

.columnList {
  display: flex;
  flex-direction: column;
  width: 80vw;
  max-width: 45rem;
  margin: 0 auto;
}

.project {
  border: 1px solid black;
  border-radius: 5px;
  margin-bottom: 2rem;
  padding: 0.5rem;

  background: rgba(230, 230, 250, 0.3);
}

.projectVisualContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projectImage {
  max-height: 60vh;
  max-width: 70vw;

  margin: 0.5rem auto auto;
  height: auto;
  width: auto;

  border-radius: 5px;
}

.projectDescription {
  display: flex;
  flex-direction: column;

  margin: 2rem 0;
  width: 80vw;
  max-width: 30rem;
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
}
.sectionHeader h3 {
  margin: auto 0;
}
.sectionHeader h4 {
  align-items: center;
  justify-content: center;
}

.icon {
  height: 2.5rem;
  padding-left: 1rem;
}

.greeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 2rem 0;
  width: 80vw;
  max-width: 30rem;
}
.greeting p,
.greeting h3 {
  text-align: center;
  padding-bottom: 1rem;
}

.stackList {
  display: grid;
  grid-template-columns: 0.4fr 0.4fr;
  grid-template-rows: 0.4fr 0.4fr;
}
.stack {
  list-style-type: square;
  padding: 0.5rem;
}

.inConstruction {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: cursive;
}

.code {
  margin: 0 auto;
  width: 77vw;
  max-width: 43.5rem;
  height: 10rem;
}
